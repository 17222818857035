@import "./common.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

/* This will work on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: darkgray #dddddd;
}

/* Target's on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #dddddd;
}

*::-webkit-scrollbar-thumb {
  background-color: darkgray;
}

.ant-table {

  .ant-table-body {

    scrollbar-width: thin;
    scrollbar-color: darkgray #dddddd;

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #dddddd;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgray;
    }

  }

}

/* ============  Start Custom CSS =========== */

/* APP layout */
.app-wrapper {
  @include fullDisplay;
  @include flexContentCenter;

  .app-page {
    margin: 5px 16px;

    .page-header {
      margin-bottom: 2px;
    }

    .page-content {
      overflow-y: auto;
      height: calc(100vh - 158px);
      background: #fff;
      margin-bottom: 10px;
      padding: 16px 24px;
    }
  }
}

